import postData, { getMethod, postFormData } from './BaseService';
import { handleResponse } from '../account/handle-response';


export default function bootCommand(bootOrder) {
    return postData('/robot/RobotBoot', bootOrder).then(handleResponse);
}

export function AddRobot(addRobot) {
    return postData('/Robot/AddRobot', addRobot).then(handleResponse);
}
export function UpdateRobot(updateRobot) {
    return postData('/Robot/UpdateRobot', updateRobot).then(handleResponse);
}

export function updateRobotForOperation(robotFormVM) {
    return postData('/Robot/UpdateRobotForOperation', robotFormVM).then(handleResponse);
}

export function getRobots() {
    return getMethod('/Robot/GetRobots').then(handleResponse);
}

export function getRobotsWithPage(page) {
    return getMethod('/Robot/getRobotsWithPage?pageNumber=' + page.pageNumber + '&sorterName=' + page.sorterName + '&ascending=' + page.ascending + '&searchString=' + page.searchString).then(handleResponse);
}

export function getRobot(robotId) {
    return getMethod('/robot/getrobot?robotId=' + robotId).then(handleResponse);
}

export function getRobotTask(robotId) {
    return getMethod('/task/getRobotTask?robotId=' + robotId).then(handleResponse);
}

export function getLiveRobot(robotId) {
    return getMethod('/Robot/GetLiveRobot?robotId=' + robotId).then(handleResponse);
}
export function getLiveRobotsOfAZone(zoneId) {
    return getMethod('/Robot/GetLiveRobotsOfAZone?zoneId=' + zoneId).then(handleResponse);
}


export function getRobotStatuses() {
    return getMethod('/Robot/GetRobotStatuses').then(handleResponse);
}

export function getRobotStatus(statusId) {
    return getMethod('/Robot/GetRobotStatus?id=' + statusId).then(handleResponse);
}

export function addRobotStatus(addRobotStatus) {
    return postData('/Robot/AddRobotStatus', addRobotStatus).then(handleResponse);
}

export function updateRobotStatus(updateRobotStatus) {
    return postData('/Robot/UpdateRobotStatus', updateRobotStatus).then(handleResponse);
}

export function deleteRobotStatus(statusId) {
    return postData('/Robot/RemoveRobotStatus?id=' + statusId).then(handleResponse);
}

export function getRobotsOfZone(zoneId) {
    return getMethod('/Robot/GetRobotsOfZone?zoneId=' + zoneId).then(handleResponse);
}

export function getRobotsOfAZoneForUser(zoneId) {
    return getMethod('/Robot/GetRobotsOfAZoneForUser?zoneId=' + zoneId).then(handleResponse);
}

export function getRobotStatusMessages(robotId) {
    return getMethod('/Robot/GetRobotStatusMessages?robotId=' + robotId).then(handleResponse);
}

export function getRobotStatusMessagesFiltered(searchVM) {
    return postData('/Robot/GetRobotStatusMessagesFiltered', searchVM).then(handleResponse);
}

export function getRobotPeriodicImages(periodicImageFilterVM) {
    return getMethod('/Robot/GetRobotPeriodicImages?robotId=' + periodicImageFilterVM.robotId + "&startTime=" + periodicImageFilterVM.startTime + "&endTime=" + periodicImageFilterVM.endTime + "&isLid=" + periodicImageFilterVM.isLid).then(handleResponse);
}

export function getLiveRobots() {
    return getMethod('/Robot/GetLiveRobots').then(handleResponse);
}

export function getInactiveRobots() {
    return getMethod('/Robot/GetInactiveRobots').then(handleResponse);
}

export function getMapVersionsForRobot(robotId) {
    return getMethod('/Robot/getMapVersionsForRobot?robotId=' + robotId).then(handleResponse);
}

export function getAllRobots() {
    return getMethod('/Robot/GetAllRobots').then(handleResponse);
}

export function costmapSafetyOverride(costmapSafetyOverrideVM) {
    return postData('/RobotServiceCall/CostmapSafetyOverride', costmapSafetyOverrideVM).then(handleResponse);
}

export function temporarySafetyOverride(costmapSafetyOverrideVM) {
    return postData('/RobotServiceCall/TemporarySafetyOverride', costmapSafetyOverrideVM).then(handleResponse);
}

export function ultrasonicSafetyOverride(ultrasonicSafetyOverrideVM) {
    return postData('/RobotServiceCall/UltrasonicSafetyOverride', ultrasonicSafetyOverrideVM).then(handleResponse);
}

export function emergencyBrake(emergencyBrakeVM) {
    return postData('/RobotServiceCall/EmergencyBrake', emergencyBrakeVM).then(handleResponse);
}

export function buzzer(robotId) {
    return postData('/RobotServiceCall/Buzzer?robotId=' + robotId).then(handleResponse);
}

export function lid(lidRequest) {
    return postData('/RobotServiceCall/Lid', lidRequest).then(handleResponse);
}

export function led(ledRequest) {
    return postData('/RobotServiceCall/Led', ledRequest).then(handleResponse);
}

export function stateTransition(transition) {
    return postData('/RobotServiceCall/StateTransition', transition).then(handleResponse);
}

export function stateTransitionFromUser(transition) {
    return postData('/RobotServiceCall/StateTransitionFromUser', transition).then(handleResponse);
}
export function fetchLogsByFilter(logsFilterVM) {
    return postData('/RobotServiceCall/FetchLogsByFilter', logsFilterVM).then(handleResponse);
}
export function saveMapToRobot(robotId) {
    return postData('/RobotServiceCall/SaveStaticMapToRobot?robotId=' + robotId).then(handleResponse);
}
export function updateRobotMap(robotId) {
    return postData('/RobotServiceCall/SaveStaticMapToRobot?robotId=' + robotId).then(handleResponse);
}
export function updateCheckpointMap(robotId) {
    return postData('/RobotServiceCall/SaveCheckpointMapToRobot?robotId=' + robotId).then(handleResponse);
}
export function handleHealthCheckerStatus(healthCheckerReqVM) {
    return postData('/RobotServiceCall/HandleHealthCheckerStatus', healthCheckerReqVM).then(handleResponse);
}

export function startRobot(robotId) {
    return postData('/RobotServiceCall/StartRobot?robotId=' + robotId).then(handleResponse);
}

export function stopRobot(robotId) {
    return postData('/RobotServiceCall/StopRobot?robotId=' + robotId).then(handleResponse);
}

export function restartRobot(robotId) {
    return postData('/RobotServiceCall/RestartRobot?robotId=' + robotId).then(handleResponse);
}

export function startROSCore(robotId) {
    return postData('/RobotServiceCall/StartROSCore?robotId=' + robotId).then(handleResponse);
}

export function stopROSCore(robotId) {
    return postData('/RobotServiceCall/StopROSCore?robotId=' + robotId).then(handleResponse);
}

export function restartROSCore(robotId) {
    return postData('/RobotServiceCall/RestartROSCore?robotId=' + robotId).then(handleResponse);
}

export function startBootReset(robotId) {
    return postData('/RobotServiceCall/StartBootReset?robotId=' + robotId).then(handleResponse);
}

export function stopBootReset(robotId) {
    return postData('/RobotServiceCall/StopBootReset?robotId=' + robotId).then(handleResponse);
}

export function restartBootReset(robotId) {
    return postData('/RobotServiceCall/RestartBootReset?robotId=' + robotId).then(handleResponse);
}

export function startNvidia(robotId) {
    return postData('/RobotServiceCall/StartNvidia?robotId=' + robotId).then(handleResponse);
}

export function stopNvidia(robotId) {
    return postData('/RobotServiceCall/StopNvidia?robotId=' + robotId).then(handleResponse);
}

export function restartNvidia(robotId) {
    return postData('/RobotServiceCall/RestartNvidia?robotId=' + robotId).then(handleResponse);
}

export function startCameraRear(robotId) {
    return postData('/RobotServiceCall/StartCameraRear?robotId=' + robotId).then(handleResponse);
}

export function stopCameraRear(robotId) {
    return postData('/RobotServiceCall/StopCameraRear?robotId=' + robotId).then(handleResponse);
}

export function restartCameraRear(robotId) {
    return postData('/RobotServiceCall/RestartCameraRear?robotId=' + robotId).then(handleResponse);
}

export function firmwareUpdate(formData) {
    return postFormData('/RobotServiceCall/FirmwareUpdate', formData).then(handleResponse);
}

export function saveVideoLogsToS3(videoLogsVM) {
    return postData('/RobotServiceCall/SaveVideoLogsToS3', videoLogsVM).then(handleResponse);
}

export function saveImageLogsToS3(imageLogsVM) {
    return postData('/RobotServiceCall/SaveImageLogsToS3', imageLogsVM).then(handleResponse);
}

export function deleteImageDirectories(imageLogsVM) {
    return postData('/RobotServiceCall/DeleteImageDirectories', imageLogsVM).then(handleResponse);
}

export function uploadAudioFiles(formData) {
    return postFormData('/RobotServiceCall/UploadAudioFiles', formData).then(handleResponse);
}

export function uploadLogsDB(robotId) {
    return postData('/RobotServiceCall/UploadLogsDb?robotId=' + robotId).then(handleResponse);
}
export function getRobotTrajectory(getRobotTrajectoryVM) {
    return getMethod('/Robot/GetRobotTrajectory' + getRobotTrajectoryVM).then(handleResponse);
}
export function isRobotCodeValid(robotCode) {
    return getMethod('/Robot/IsRobotCodeValid?robotCode=' + robotCode).then(handleResponse);
}
export function getRobotsVersionsFromDb() {
    return getMethod('/Robot/GetRobotsVersionsFromDb').then(handleResponse);
}
export function updateRobotVersionsForDb(robotId) {
    return postData('/Robot/UpdateRobotVersionsForDb?robotId=' + robotId).then(handleResponse);
}
export function updateRobotsVersionsForDb() {
    return postData('/Robot/UpdateRobotsVersionsForDb').then(handleResponse);
}
export function getCameraLogDates(robotId) {
    return getMethod('/RobotServiceCall/GetCameraLogDates?robotId=' + robotId).then(handleResponse);
}
export function autoFPSLowMode(autoFPSLowModeVM) {
    return postData('/RobotServiceCall/AutoFPSLowMode', autoFPSLowModeVM).then(handleResponse);
}
export function sendProgramsToRobot(robotId) {
    return postData('/RobotServiceCall/SendProgramsToRobot?robotId=' + robotId).then(handleResponse);
}

export function setHQImageLogMode(hQImageLogModeRequestVM) {
    return postData('/RobotServiceCall/SetHQImageLogMode', hQImageLogModeRequestVM).then(handleResponse);
}

export function saveOperatorCommands(req) {
    return postData('/Robot/SaveOperatorCommands', req).then(handleResponse);
}

export function saveRobotFpsValues(req) {
    return postData('/Robot/SaveRobotFpsValues', req).then(handleResponse);
}

export function getOperatorCommands(searchVM) {
    return postData('/Robot/GetOperatorCommands', searchVM).then(handleResponse);
}

export function getTimeOffset(dateTime) {
    return getMethod('/TimeSync/GetTimeOffset?localTime=' + dateTime).then(handleResponse);
}

export function getRobotLogs(robotLogSearchVM) {
    return postData('/Robot/GetRobotLogs', robotLogSearchVM).then(handleResponse);
}

export function mCSafety(mCSafetyRequestVM) {
    return postData('/RobotServiceCall/MCSafety', mCSafetyRequestVM).then(handleResponse);
}

export function ultrasonicDisable(ultrasonicDisableRequestVM) {
    return postData('/RobotServiceCall/UltrasonicDisable', ultrasonicDisableRequestVM).then(handleResponse);
}

export function kickManualControlUser(robotId) {
    return postData('/Robot/KickManualControlUser?robotId=' + robotId).then(handleResponse);
}

export function modemReboot(robotId)
{
    return postData('/RobotServiceCall/ModemReboot?robotId=' + robotId).then(handleResponse);
}

export function freeRobotFromPreOrder(req)
{
    return postData(`/Robot/FreeRobotFromPreOrder?robotId=${req.robotId}&setMaintenance=${req.setMaintenance}`).then(handleResponse)
}

export function getRobotStartupStatus(robotId) {
    return getMethod('/Robot/GetRobotStartupStatus?robotId=' + robotId).then(handleResponse);
}

export function fetchRobotDistances(robotDistanceSearchVM)
{
    return postData('/Robot/FetchRobotDistances', robotDistanceSearchVM).then(handleResponse)
}

export function reassignPreOrdersOfRobot(robotId)
{
    return postData(`/Robot/ReassignPreOrdersOfRobot?robotId=${robotId}`).then(handleResponse)
}

export function resetPublicIPOnRAMForRobot(robotId)
{
    return postData(`/Robot/ResetPublicIPOnRAMForRobot?robotId=${robotId}`).then(handleResponse)
}

export function uploaderDisable(uploaderDisableVM)
{
    return postData("/RobotServiceCall/UploaderDisable", uploaderDisableVM).then(handleResponse)
}