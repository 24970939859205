import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { authenticationService } from './account/authentication.service';
import { PrivateRoute } from './account/PrivateRoute';
import Login from './account/Login'
import OrderPageLogin from './customer/OrderPage/OrderPageLogin';
import './i18n';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const OrderPage = React.lazy(() => import('./customer/OrderPage/OrderPage'));
const ReservationPage = React.lazy(() => import('./customer/Reservation/ReservationPage'));
const EvriLogin = React.lazy(() => import('./account/EvriLogin'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };

  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }


  render() {
    return (
      <>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/spitz/:robotCode" name="Order Login Page" render={props => <OrderPageLogin {...props} />} />
              <Route exact path="/spitz/:robotCode/:orderCode" name="OrderPage" component={OrderPage} />
              <Route exact path="/reservation/:cargoCode" name="ReservationPage" component={ReservationPage} />
              if (!process.env.NODE_ENV === 'customer') {
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                  <Route exact path="/evri/login" name="Evri Login" component={EvriLogin} />
                  <PrivateRoute path="/" name="Home" component={TheLayout} />
                </Switch>
              }
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>

    );
  }
}

export default App;

